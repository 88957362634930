<template>
  <section class="about-history">
    <div class="container">
      <div class="tag tag--float is-uppercase">Nuestra Historia</div>
      <h3 class="history__subtitle">{{ data.title }}</h3>

      <div class="timeline timeline--vertical">
        <ul class="timeline__items">

          <li 
            class="timeline__item reveal"
            v-for="(val, index) in data.timeline"
            :key="index">

            <span class="timeline__event">{{ val.year }}</span>

            <div class="timeline__item-container">
              <h3 class="timeline__item__title">{{ val.title }}</h3>

              <div class="timeline__item-content">

                <div class="timeline__item-content__left">
                  <p class="has-text-blue is-uppercase">{{ val.subtitle }}</p>
                  <!-- <p>{{ val.body }}</p> -->
                  <ul class="list-arrow">
                    <li v-for="(item, i) in val.list" :key="i">{{ item.text }}</li>
                  </ul>
                </div>

                <div class="timeline__item-content__right" v-if="val.image">
                  <figure class="img img--square">
                    <img :src="val.image.url" :alt="val.caption" class="img--rounded">
                  </figure>
                </div>

              </div>
            </div><!-- .timeline__item-container -->

          </li><!-- .timeline__item -->

        </ul>
      </div><!-- .timeline -->
    </div><!-- .container -->

    <div class="gallery gallery--bordered">
      <div class="gallery__container">

        <div 
          class="gallery__picture"
          v-for="(val, index) in data.images">
          <img :src="val.image.url" :alt="val.caption">
          <div v-if="val.caption" class="gallery-picture__caption">
            <span class="pl-1">{{ val.caption }}</span>
          </div>
        </div>

      </div>
    </div>

  </section><!-- .about-history -->
</template>

<script>
export default {
  name: 'History',
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="css">

.img {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.img img {
  min-width: 100%;
  min-height: 100%;
  margin: auto;
  position: absolute;
  top: -100%;
  right: -100%;
  bottom: -100%;
  left: -100%;
}

.img--square {
  border-radius: 2400px;
  width: 400px;
  height: 400px;
}

@media screen and (max-width: 768px) {
  .img--square {
    width: 228px;
    height: 228px;
  }
}

</style>