<template>
	<section class="contact" id="contacto">
    <div class="container">
      <h2 class="contact__title" v-html="data.title"></h2>
      <div class="contact__columns">
        <div class="contact__column has-text-centered reveal">
          <div class="has-text-blue is-uppercase">{{ data.requestTitle }}</div>
          <p v-html="data.requestText"></p>
        </div>
        <div class="contact__column has-text-centered reveal">
          <div class="has-text-blue is-uppercase">{{ data.orderTitle }}</div>
          <a :href="'mailto:' + data.orderText" class="button is-blue is-uppercase">{{ data.orderText }}</a>
        </div>
        <div class="contact__column has-text-centered reveal">
          <div class="has-text-blue is-uppercase">{{ data.followUpTitle }}</div>
          <a :href="'mailto:' + data.followUpText" class="button is-blue is-uppercase">{{ data.followUpText }}</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Contact',
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>
