<template>
	<footer class="footer">
    <div class="container">
      <div>
        <div class="footer__copyright">
          <!-- <p>Printel &copy; {{ new Date().getFullYear() }}</p>
          <p>Todo los derecho reservados</p> -->
          <span v-html="site.copyright"></span>
        </div>
        <div class="footer__location">
          <!-- <p>Ave. 25 de Mayo No. 289 <br> Col. Trabajadores <br>Santa Catarina, N.L. 66149</p> -->
          <span v-html="site.location"></span>
        </div>
      </div>

      <div class="footer__social">
        <a :href="site.facebook" class="footer__social__item footer__social__item--facebook">
          <img src="~images/facebook.svg" alt="">
        </a>
        <a :href="site.instagram" class="footer__social__item footer__social__item--instagram">
          <img src="~images/instagram.svg" alt="">
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterApp',
  props: {
    site: {
      type: Object
    }
  }
}
</script>