<template>
  <div>
    <navbar :site="site"></navbar>
    <hero :data="hero"></hero>
    <printeligence :data="printeligence"></printeligence>
    <printeligence-technology :data="technology"></printeligence-technology>
    <printeligence-certifications :data="certifications"></printeligence-certifications>
    <about :data="about"></about>
    <visual-impacts :data="impacts"></visual-impacts>
    <history :data="history"></history>
    <contact :data="contact"></contact>
    <footer-app :site="site"></footer-app>
  </div>
</template>

<script>
import { db } from './firebase'

import Navbar from './components/Navbar'
import Hero from './components/Hero'
import Printeligence from './components/Printeligence'
import PrinteligenceTechnology from './components/PrinteligenceTechnology'
import PrinteligenceCertifications from './components/PrinteligenceCertifications'
import About from './components/About'
import History from './components/History'
import VisualImpacts from './components/VisualImpacts'
import Contact from './components/Contact'
import FooterApp from './components/FooterApp'

export default {
  name: 'app',
  components: {
    Navbar,
    Hero,
    Printeligence,
    PrinteligenceTechnology,
    PrinteligenceCertifications,
    About,
    History,
    VisualImpacts,
    Contact,
    FooterApp
  },
  data () {
    return {
      site: {},
      hero: {},
      printeligence: {},
      technology: {},
      certifications: {},
      about: {},
      history: {},
      impacts: {},
      contact: {}
    }
  },
  firestore: {
    site: db.collection('settings').doc('site'),
    hero: db.collection('sections').doc('hero'),
    printeligence: db.collection('sections').doc('printeligence'),
    technology: db.collection('sections').doc('technology'),
    certifications: db.collection('sections').doc('certifications'),
    about: db.collection('sections').doc('about'),
    history: db.collection('sections').doc('history'),
    impacts: db.collection('sections').doc('visualImpacts'),
    contact: db.collection('sections').doc('contact')
  }
}
</script>
