<template>
  <section class="printelligence-technology">
    <div class="container">
      <h3 class="printelligence-technology__title" >{{ data.title }}</h3>
    </div>

    <div class="printelligence-technology__pics" v-if="data.rigidos">
      <div class="container">
        <h4 class="printelligence-technology__pics__title">{{ data.rigidos.title }}</h4>
      </div>
      <div class="gallery">
        <div class="gallery__container">
          <div 
            class="gallery__picture reveal" 
            v-for="(val, key) in data.rigidos.images"
            :key="key">
            <img :src="val.image.url" :alt="val.caption">
          </div>
        </div>
      </div>
    </div><!-- .printelligence-technology__pics -->

    <div class="printelligence-technology__pics" v-if="data.flexibles">
      <div class="container">
        <h4 class="printelligence-technology__pics__title">{{ data.flexibles.title }}</h4>
      </div>
      <div class="gallery">
        <div class="gallery__container">
          <div 
            class="gallery__picture reveal" 
            v-for="(val, key) in data.flexibles.images"
            :key="key">
            <img :src="val.image.url" :alt="val.caption">
          </div>
        </div>
      </div>
    </div><!-- .printelligence-technology__pics -->

    <div class="printelligence-technology__pics" v-if="data.empaque">
      <div class="container">
        <h4 class="printelligence-technology__pics__title">{{ data.empaque.title }}</h4>
      </div>
      <div class="gallery">
        <div class="gallery__container">
          <div 
            class="gallery__picture reveal" 
            v-for="(val, key) in data.empaque.images"
            :key="key">
            <img :src="val.image.url" :alt="val.caption">
          </div>
        </div>
      </div>
    </div><!-- .printelligence-technology__pics -->

    <div class="printelligence-technology__pics" v-if="data.coroplast">
      <div class="container">
        <h4 class="printelligence-technology__pics__title">{{ data.coroplast.title }}</h4>
      </div>
      <div class="gallery">
        <div class="gallery__container">
          <div 
            class="gallery__picture reveal" 
            v-for="(val, key) in data.coroplast.images"
            :key="key">
            <img :src="val.image.url" :alt="val.caption">
          </div>
        </div>
      </div>
    </div><!-- .printelligence-technology__pics -->

  </section>
</template>

<script>
export default {
  name: 'PrinteligenceTechnology',
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>
