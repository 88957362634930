// import './module.js'
import Vue from 'vue'
// import Vue from 'vue/dist/vue.js'
import App from './App.vue'
import VueScrollactive from 'vue-scrollactive'
// import VueScrollReveal from 'vue-scroll-reveal'
import VueGlide from 'vue-glide-js'
import 'vue-glide-js/dist/vue-glide.css'
import { firestorePlugin } from 'vuefire'

Vue.config.productionTip = false

Vue.use(firestorePlugin)
Vue.use(VueScrollactive)
// Vue.use(VueScrollReveal)
Vue.use(VueGlide)

new Vue({
  render: h => h(App),
}).$mount('#app')
