<template>
  <section class="about" id="sobrenosotros">
    <div class="container">

      <div class="tag tag--float is-uppercase">Sobre Nosotros</div>

      <h2 class="about__title h1">{{ data.title }}</h2>
      <h3 class="about__subtitle">{{ data.intro }}</h3>

      <div class="about__content" v-if="data.blockOne && data.blockTwo">
        <div class="about__content--half reveal">
          <div class="has-text-blue is-uppercase">{{ data.blockOne.title }}</div>
          <p>{{ data.blockOne.text }}</p>
        </div>
        <div class="about__content--half reveal">
          <div class="has-text-blue is-uppercase">{{ data.blockTwo.title }}</div>
          <p>{{ data.blockTwo.text }}</p>
        </div>
      </div>
    </div>

    <vue-glide
      class="border-top-1 border-bottom-1 border-primary pt-1 pb-1 pl-1 pr-1"
      type="carousel"
      :per-view="perView"
      :breakpoints="breakpoints"
      v-if="data.images"
    >
      <vue-glide-slide v-for="(val, key) in data.images" :key="key">
        <img :src="val.image.url" :alt="val.caption">
        <div v-if="val.caption" class="carousel__item__caption is-uppercase">
          <span class="pl-1">{{ val.caption }}</span>
        </div>
      </vue-glide-slide>
      <template slot="control">
        <div class="carousel__prev" data-glide-dir="<"><img src="~images/prev.svg"></div>
        <div class="carousel__next" data-glide-dir=">"><img src="~images/next.svg"></div>
      </template>
    </vue-glide>

  </section><!-- .about -->
</template>

<script>
export default {
  name: 'About',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      perView: 3,
      breakpoints: {
        600: { perView: 1 },
        1200: { perView: 3 }
      }
    }
  }
}
</script>

<style lang="css">
[data-glide-el=controls] {
  position: absolute;
  right: 10px;
  display: -webkit-flex;
  display: flex;
  top: -20px;
}
</style>
