<template>
	<section class="printelligence-certifications">
    <div class="container">
      <h3 class="printelligence-certifications__title">Certificaciones y alianzas</h3>

      <div class="printelligence-certifications__items">
        <div 
        class="printelligence-certifications__item reveal"
        v-for="(val, key) in data.images"
        :key="key">
          <img :src="val.image.url" :alt="val.caption">
        </div>
      </div>

    </div><!-- .printelligence-certifications__items -->
  </section><!-- .printelligence-certifications -->
</template>

<script>
export default {
  name: 'PrinteligenceCertifications',
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="css" scoped>
</style>