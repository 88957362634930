<template>
  <section class="impacts" id="impactosvisuales">
    <div class="container">
      <h2 class="h1 impacts__title">{{ data.title }}</h2>

      <div 
        class="impacts-row"
        v-for="(val, index) in data.impacts"
        :key="index">
        <div class="impacts-column impacts-column--left reveal">
          <h4 class="impacts-column__title">{{ val.title }}</h4>
          <h4 class="impacts-column__subtitle">{{ val.meters }}</h4>
        </div>
        <div class="impacts-column impacts-column--right">
          <div 
            class="impacts-column__image reveal"
            v-for="(img, i) in val.images"
            :key="i">
            <figure class="img img--square">
              <img :src="img.image.url" :alt="img.caption" class="img--rounded">
            </figure>
            <span v-if="img.caption" class="impacts-column__image__caption">{{ img.caption }}</span>
          </div>
        </div>
      </div><!-- .impacts-row -->

    </div>
  </section><!-- .impacts -->
</template>

<script>
export default {
  name: 'VisualImpacts',
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="css">
.impacts-column__image {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.img {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.img img {
  min-width: 100%;
  min-height: 100%;
  margin: auto;
  position: absolute;
  top: -100%;
  right: -100%;
  bottom: -100%;
  left: -100%;
}

.img--square {
  border-radius: 2400px;
  width: 232px;
  height: 232px;
}
</style>