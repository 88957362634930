<template>
	<section class="printelligence" id="printeligence">

    <div class="container">
      <div class="printelligence__logo reveal">
        <img :src="data.logo.url" alt="" v-if="data.logo">
        <img src="~images/Printeligence.jpg" alt="" v-else>
      </div>
      <div class="printelligence__foo">
        <h1>{{ data.title }}</h1>
      </div>
    </div>

    <div class="gallery gallery--bordered">
      <div class="gallery__container">
        <div 
          class="gallery__picture reveal"
          v-for="(val, key) in data.gallery"
          :key="key">
          <img :src="val.image.url" :alt="val.caption">
          <div v-if="val.caption" class="gallery-picture__caption is-uppercase">
            <span class="pl-1">{{ val.caption }}</span>
          </div>
        </div>
      </div>
    </div>

		<div class="printelligence-content">

	    <div class="container">

	      <div class="tag sticky tag--float is-uppercase">Printeligence</div>

	      <h3 class="printelligence-content__text-first">{{ data.heading1 }}</h3>

	      <p class="h1 printelligence-content__text-second">{{ data.heading2 }}</p>

	      <div class="details">
	        <p class="detail printelligence-content__text-foo">{{ data.text1 }}</p>
	        <p class="detail printelligence-content__text-foo">{{ data.text2 }}</p>
	      </div>

	      <div class="timeline timeline--horizontal">
	        <!-- <hr> -->
	        <ul class="timeline__items">
	          <li 
	            class="timeline__item has-text-centered"
	            v-for="(val, key) in data.timeline"
	            :key="key">
	            <div>{{ val.text }}</div>
	          </li>
	        </ul>
	      </div>

	    </div>
	    
		</div>

  </section><!-- .printelligence -->
</template>

<script>
export default {
  name: 'Printeligence',
  props: {
  	data: {
  		type: Object,
  		required: true
  	}
  }
}
</script>
