<template>
  <nav class="navbar has-background-blue">
    <a href="/" class="navbar__logo">
      <img :src="site.logo.url" :alt="site.title" v-if="site.logo">
      <img src="~images/logo.svg" alt="" v-else>
    </a>

    <scrollactive
      :offset="60"
      :duration="2000"
      :always-track="true"
      class="navbar__menu"
      :class="{ 'show-on-click': menuActive }">
      <a href="#printeligence" class="navbar__item is-uppercase scrollactive-item">Printeligence</a>
      <a href="#sobrenosotros" class="navbar__item is-uppercase scrollactive-item">Sobre Nosotros</a>
      <a href="#impactosvisuales" class="navbar__item is-uppercase scrollactive-item">Impactos Visuales</a>
      <a href="#contacto" class="navbar__item is-uppercase scrollactive-item">Contáctanos</a>
    </scrollactive>

    <div class="navbar__contact hide-for-mobile">
      <a :href="'tel:' + site.phone">{{ site.phone }}</a>
    </div>

    <button 
      class="hamburger hamburger--squeeze hide-for-desktop"
      :class="{ 'is-active': menuActive }"
      type="button"
      @click="menuActive = !menuActive">
      <span class="hamburger-box">
        <span class="hamburger-inner"></span>
      </span>
    </button>
  </nav><!-- .navbar -->
</template>

<script>
export default {
  name: 'Navbar',
  props: {
    site: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      menuActive: false
    }
  },
  // mounted () {
  //   document.title = `${this.site.title} — Printel`
  //   document.description = this.site.description
  // }
}
</script>
