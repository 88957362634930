<template>
	<section class="hero">
    <div v-if="data">
      <div class="hero__logo headline">
        <img :src="data.logo.url" alt="" v-if="data.logo">
      </div>
      <div class="hero__text reveal">
        <h2>{{ data.title }}</h2>
      </div>
    </div>
  </section><!-- .hero -->
</template>

<script>
export default {
  name: 'Hero',
  props: {
  	data: {
  		type: Object,
  		required: true
  	}
  }
}
</script>
